<template>
  <b-col class="mt-4">
    <b-card bg-variant="dark" text-variant="white" class="text-center">
      <b-card-title>Loading...</b-card-title>
      <b-card-text>
        <b-spinner variant="primary"></b-spinner>
      </b-card-text>
    </b-card>
  </b-col>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style></style>
