<template>
  <b-container>
    <b-row>
      <Loader v-if="loading" />
      <template v-else>
        <b-col v-if="saleDateStarted !== true" class="mt-4">
          <b-card bg-variant="dark" text-variant="white">
            <b-card-text>
              <flip-countdown
                :deadline="saleDateStarted"
                @timeElapsed="saleDateStarted = true"
              ></flip-countdown>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col v-else md="8" class="mt-4">
          <b-card class="mb-2" bg-variant="dark" text-variant="white">
            <b-card-title title-tag="h1">{{
              collect ? "Token Collection" : "Checkout"
            }}</b-card-title>
            <b-card-sub-title class="mb-4">{{
              collectionData.title
            }}</b-card-sub-title>

            <b-alert :show="!!error" variant="danger">{{ error }}</b-alert>

            <b-card-text v-if="checkoutMessage">
              <div v-html="checkoutMessage"></div>
              <div v-if="status === 'queued'" style="color: orange">
                <div v-if="queuePlacement">
                  Your Queue Position: {{ queuePlacement }}
                </div>
                <div v-if="available && !collectionData.hideMintQty">
                  Available:
                  {{ collectionData.limit == 99999 ? "Unlimited" : available }}
                </div>
                <div
                  v-if="available - queuePlacement < 0"
                  class="small-text mt-2"
                >
                  <b-alert show variant="primary">
                    There appears to be less items available for purchase than
                    where you are currently in the queue. However some customers
                    ahead of you could fail to make payment, so wait here and
                    you may get chance to purchase. Your queue position is being
                    maintained.
                  </b-alert>
                </div>
              </div>
            </b-card-text>
            <b-card-text v-if="showAddress">
              <div class="mb-2 text-large">
                <label class="fs-4"
                  >{{ collect ? "Amount to Send (₳)" : "Price (₳)" }}:</label
                >
                <b-input-group>
                  <b-form-input :value="adaPrice" readonly></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      title="Copy"
                      class="copy-button"
                      @click="copy(adaPrice)"
                      >Copy</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
                <small class="text-warning ml-3"
                  >(Send exact amount shown)</small
                >
              </div>

              <div class="mb-2">
                <label class="fs-4">Address:</label>
                <b-input-group>
                  <b-form-input
                    :value="address"
                    style="font-size: small"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      title="Copy"
                      class="copy-button"
                      @click="copy(address)"
                      >Copy</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="mt-4">
                <b-alert show variant="success">
                  <p>
                    Opening multiple browser windows will show you the same
                    address. Multiple transactions to the same address will fail
                    and get refunded.
                  </p>
                  <ul>
                    <li>Send the exact amount specified, including the dust</li>
                    <li>Only send a single transaction</li>
                    <li>Do not send ADA directly from an exchange</li>
                    <li>
                      Only send from a Cardano wallets such as Daedalus, Nami,
                      Eternl or Yoroi
                    </li>
                    <li>
                      To purchase multiple tokens you need to rejoin the queue
                      and make another purchase
                    </li>
                  </ul>
                </b-alert>
              </div>
            </b-card-text>
            <div>
              <b-form-checkbox
                v-if="termsURL && showBuy"
                v-model="checked"
                value="checked"
                class="mb-2"
              >
                <span style="margin-left: 10px"
                  >Please accept these terms before purchase (<a
                    :href="termsURL"
                    target="_blank"
                    >{{ termsURL }}</a
                  >)
                </span>
              </b-form-checkbox>
              <b-overlay
                v-if="showBuy"
                :show="waiting"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="success"
                class="d-inline-block"
              >
                <b-button
                  pill
                  ref="button"
                  :disabled="waiting || !checked"
                  variant="success"
                  @click.prevent="buy"
                  v-if="!collect"
                >
                  {{
                    status === "paid"
                      ? "Buy another"
                      : `Buy ${collectionData.baseMintQty} ₳${
                          collectionData.price / 1000000
                        }`
                  }}
                </b-button>
                <b-button
                  pill
                  ref="button"
                  :disabled="waiting || !checked"
                  variant="success"
                  @click.prevent="buy"
                  v-if="collect"
                >
                  Collect Token
                </b-button>
              </b-overlay>
            </div>

            <div v-if="status === 'whitelist'">
              <label for="stake-key">Wallet address:</label>

              <b-input-group>
                <b-form-input
                  id="stake-key"
                  v-model.trim="stakeKey.value"
                  :state="stakeKey.state"
                  :placeholder="`${
                    !!stakeKey.message
                      ? stakeKey.message
                      : 'Check your wallet is on the whitelist'
                  }`"
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="checkWhitelist"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="success"
                    @click="checkWhitelist"
                    :disabled="stakeKey.loading"
                  >
                    <b-spinner v-if="stakeKey.loading" small></b-spinner>
                    <span v-else>Check</span>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <div v-if="errors">
                <div v-for="(error, index) of errorObj.value" :key="index">
                  <span style="color: #e64b64" class="pl-1">{{ error }}</span>
                </div>
              </div>
            </div>

            <flip-countdown
              v-if="countdown"
              :deadline="countdown"
              :showDays="false"
              :showHours="true"
              @timeElapsed="status = 'timeout'"
            ></flip-countdown>

            <div v-if="lastUpdated" class="text-muted">
              <small>Last updated: {{ lastUpdated }}</small>
            </div>
          </b-card>
        </b-col>
        <b-col :md="saleDateStarted !== true ? '12' : '4'" class="mt-4">
          <b-card
            no-body
            class="overflow-hidden"
            bg-variant="dark"
            text-variant="white"
          >
            <b-row no-gutters>
              <b-col md="12">
                <b-card-img
                  :src="collectionData.image"
                  alt="Artwork"
                  class="rounded-0"
                ></b-card-img>
              </b-col>
              <b-col md="12">
                <b-card-body>
                  <b-card-title>
                    {{ collectionData.title }}
                  </b-card-title>
                  <b-card-text class="mt-2">
                    <div v-html="collectionData.description"></div>
                    <div v-if="!collect" class="mt-4">
                      <span style="color: #2fee41">Price:</span> ₳{{
                        collectionData.price | lovelace
                      }}
                      <small>(plus dust)</small>
                      <br />
                      <div v-if="!collectionData.hideMintQty">
                      <span style="color: #2fee41">Total to be minted:</span>
                      {{
                        collectionData.limit == 99999
                          ? "Unlimited"
                          : collectionData.limit
                      }}<br />
                      </div>
                    </div>
                  </b-card-text>
                  <div class="d-flex align-items-end h1">
                    <b-card-sub-title class="h4 mt-4">
                      Find out more
                    </b-card-sub-title>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-end h1"
                  >
                    <div class="d-flex">
                      <b-link :href="collectionData.website" target="_blank">
                        <b-icon icon="globe2" variant="success"></b-icon>
                      </b-link>
                    </div>
                    <div class="d-flex">
                      <b-link :href="collectionData.twitter" target="_blank">
                        <b-icon icon="twitter" variant="primary"></b-icon>
                      </b-link>
                    </div>
                    <div class="d-flex">
                      <b-link :href="collectionData.discord" target="_blank">
                        <b-icon icon="discord" variant="info"></b-icon>
                      </b-link>
                    </div>
                  </div>
                  <div v-if="endDate">
                    Ending:
                    <flip-countdown
                      :deadline="endDate"
                      :showDays="false"
                      :showHours="false"
                      @timeElapsed="status = 'ended'"
                    ></flip-countdown>
                  </div>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </template>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import validate from "validate.js";
import FlipCountdown from "vue2-flip-countdown";
import axios from "axios";
import { db, enterQueue, checkOnWhitelist } from "@/firebase";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import Loader from "@/components/Loader";

export default {
  name: "Checkout",
  components: {
    FlipCountdown,
    Loader,
  },
  data() {
    return {
      errors: false,
      errorObj: null,
      sendRequest: false,
      loading: true,
      checkoutMessage: null,
      checking: false,
      collection: this.$route.params.collection,
      waiting: false,
      collectionData: null,
      address: null,
      status: null,
      uuid: null,
      price: null,
      error: null,
      saleDateStarted: null,
      endDate: null,
      whitelistDate: null,
      countdown: null,
      queuePosition: null,
      position: null,
      available: null,
      lastUpdated: null,
      buyerDocUnsubscribe: null,
      collectionDocUnsubscribe: null,
      exhaustedLimit: null,
      termsURL: null,
      checked: false,
      stakeKey: {
        value: "",
        state: null,
        message: null,
        loading: false,
      },
      branding: {
        bgColor: null,
        headColor: null,
        logoUrl: null,
      },
    };
  },
  async created() {
    this.collectionDocUnsubscribe = onSnapshot(
      doc(db, "collections", this.collection),
      (collectionDoc) => {
        if (collectionDoc.exists()) {
          this.collectionData = collectionDoc.data();

          const {
            startDate,
            endDate,
            limit,
            soldCount,
            queuePosition,
            failCount,
            whitelistDate,
            type,
            termsURL,
            branding,
          } = this.collectionData;

          this.available = limit - soldCount;
          this.queuePosition = queuePosition;
          this.collect = type === "collect" ? true : false;
          this.whitelistDate = whitelistDate;
          this.termsURL = termsURL;

          // Override styles if we are branding the page
          if (branding) {
            if (branding.bgImage) {
              document.body.style.background = `url(${branding.bgImage})`;
              document.body.style.backgroundSize = "cover";
            }
            if (branding.bgColor) {
              document.body.style.background = "none";
              document.body.style.backgroundColor = branding.bgColor;
            }
            if (branding.headColor) {
              document.getElementById("nav").style.backgroundColor =
                branding.headColor;
            }
            if (branding.logoUrl) {
              document.getElementById(
                "logo"
              ).style.backgroundImage = `url(${branding.logoUrl}`;
            }
          }

          // only allow a certain amount of users to queue
          // 25% buffer on the limit and add any that may have failed
          this.exhaustedLimit = Math.ceil(limit * 1.25) + failCount;

          const startDateTimer = moment(startDate.toDate());
          if (startDateTimer.format() <= moment().format()) {
            this.saleDateStarted = true;
          } else {
            this.saleDateStarted = startDateTimer.format("YYYY-MM-DD HH:mm:ss");
          }

          if (endDate) {
            const endDateMoment = moment(endDate.toDate());
            if (endDateMoment.format() <= moment().format()) {
              this.status = "ended";
              this.countdown = null;
              this.loading = false;
              return;
            } else if (
              endDateMoment.format() <= moment().add(1, "hour").format()
            ) {
              this.endDate = endDateMoment.format("YYYY-MM-DD HH:mm:ss");
            }
          }

          if (soldCount >= limit) {
            this.status = "sold out";
          }

          // Terms & conditions checkbox
          if (termsURL) {
            this.checked = false;
          } else {
            this.checked = true;
          }

          const existingUuid = this.getLocalStorageUuid();
          if (existingUuid) {
            this.uuid = existingUuid;
            if (this.buyerDocUnsubscribe === null) {
              this.snapshot();
            }
          } else {
            this.runWhitelistDateLogic();
            this.loading = false;
          }
        } else {
          this.$router.push(`/${this.$route.params.client}`);
        }
      }
    );
  },
  destroyed() {
    if (this.buyerDocUnsubscribe !== null) {
      this.buyerDocUnsubscribe();
    }
    this.collectionDocUnsubscribe();
  },
  computed: {
    adaPrice() {
      // return this.$options.filters.lovelace(this.price);
      const priceAsString = this.price.toString();
      const position = priceAsString.length - 6;
      const output = [
        priceAsString.slice(0, position),
        ".",
        priceAsString.slice(position),
      ].join("");
      return output;
    },
    showBuy() {
      return ![
        "whitelist",
        "queued",
        "available",
        "pending",
        "timeout",
        "exhausted",
        "sold out",
        "ended",
      ].includes(this.status);
    },
    showAddress() {
      return this.address && ["available", "pending"].includes(this.status);
    },
    queuePlacement() {
      if (!this.position) {
        return null;
      }
      const quPo = this.position - this.queuePosition;
      return quPo > 0 ? quPo : null;
    },
  },
  methods: {
    processResponse(data, fromBuy) {
      const { addr, status, uuid, price, startDate } = data;
      // timer is from "availableTimestamp"

      if (startDate) {
        this.saleDateStarted = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
      }

      this.status = status;
      if (addr) {
        this.address = addr;
      }

      if (price) {
        this.price = price;
      }

      if (uuid) {
        this.uuid = uuid;
        this.updateLocalStorage();
      }

      if (["paid", "failed", "expired"].includes(status)) {
        this.removeLocalStorage();
      }

      if (fromBuy) {
        this.snapshot();
      }

      this.waiting = false;
    },
    snapshot() {
      this.buyerDocUnsubscribe = onSnapshot(
        doc(db, "buyers", this.uuid),
        (buyerDoc) => {
          if (buyerDoc.exists()) {
            const data = buyerDoc.data();
            this.processResponse(data);

            const { position, availableTimestamp, status } = data;
            this.position = position;
            this.lastUpdated = moment().format("h:mm:ss a");

            if (
              availableTimestamp &&
              ["available", "pending"].includes(status)
            ) {
              const timerTimestamp = moment(availableTimestamp.toDate()).add(
                30,
                "minutes"
              );
              this.countdown = timerTimestamp.format("YYYY-MM-DD HH:mm:ss");
              if (timerTimestamp <= moment()) {
                this.status = "timeout";
                this.countdown = null;
              }
            } else {
              this.countdown = null;
            }
          } else {
            this.removeLocalStorage();
            this.status = "failed";
          }
          this.waiting = false;
          this.loading = false;
        }
      );
    },
    buy() {
      if (this.runWhitelistDateLogic()) {
        return;
      }
      this.waiting = true;
      enterQueue({ collection: this.collection })
        .then((result) => {
          this.processResponse(result.data, true);
          this.loading = false;
        })
        .catch((err) => {
          this.createError(err);
          this.waiting = false;
        });
    },
    check() {
      updateDoc(doc(db, "buyers", this.uuid), {
        lastChecked: moment().toDate(),
      });
    },
    createError(err) {
      console.log(err);
      this.error = "There was an error";
      setTimeout(() => {
        this.error = null;
      }, 2500);
    },
    getLocalStorage() {
      const retronft = localStorage.getItem("retronft");
      if (retronft) {
        return JSON.parse(retronft);
      } else {
        return null;
      }
    },
    getLocalStorageUuid() {
      const storage = this.getLocalStorage();
      if (storage) {
        if (Array.isArray(storage)) {
          const heldCollection = storage.find(
            (item) => item.collection === this.collection
          );
          if (heldCollection) {
            return heldCollection.uuid;
          }
        } else {
          return storage.uuid;
        }
      }
      return null;
    },
    updateLocalStorage() {
      const storage = this.getLocalStorage();
      if (storage) {
        if (Array.isArray(storage)) {
          let filtered = storage.filter(
            (item) => item.collection !== this.collection
          );
          filtered.push({
            collection: this.collection,
            uuid: this.uuid,
          });
          localStorage.setItem("retronft", JSON.stringify(filtered));
        } else {
          localStorage.setItem(
            "retronft",
            JSON.stringify([
              {
                collection: this.collection,
                uuid: this.uuid,
              },
            ])
          );
        }
      } else {
        localStorage.setItem(
          "retronft",
          JSON.stringify([
            {
              collection: this.collection,
              uuid: this.uuid,
            },
          ])
        );
      }
    },
    removeLocalStorage() {
      const storage = this.getLocalStorage();
      if (storage) {
        if (Array.isArray(storage)) {
          const filtered = storage.filter(
            (item) => item.collection !== this.collection
          );
          if (filtered.length) {
            localStorage.setItem("retronft", JSON.stringify(filtered));
          } else {
            localStorage.removeItem("retronft");
          }
        } else {
          localStorage.removeItem("retronft");
        }
      }
    },
    copy(value) {
      navigator.clipboard.writeText(value);
    },
    checkExhaustedLimit() {
      if (
        this.exhaustedLimit &&
        this.position &&
        this.position > this.exhaustedLimit
      ) {
        this.status = "exhausted";
      }
    },
    async checkWhitelist() {
      this.stakeKey.loading = true;
      this.sendRequest = false;
      // This constraint avoids whitespaces in the middle of the text. v-model.trim avoids whitespaces on the edges
      const stakeKeyConstr = {
        value: {
          format: {
            pattern: /^\S+$/,
            message: "^Whitespace not allowed",
          },
        },
      };

      const stakeKeyVal = validate(this.stakeKey, stakeKeyConstr);

      if (stakeKeyVal) {
        this.errors = true;
        this.errorObj = stakeKeyVal;
      } else {
        this.errors = false;
        // check for stakey/addr format
        const regex = new RegExp(/(addr|stake)[a-z0-9]+/);
        const passRegex = regex.test(this.stakeKey.value);

        if (passRegex) {
          this.sendRequest = true;
        } else {
          await this.getAddrFromAdahandle(this.stakeKey.value);
        }

        if (this.sendRequest) {
          await checkOnWhitelist({
            address: this.stakeKey.value,
            collection: this.collection,
          }).then((res) => {
            const { success, message } = res.data;
            if (success) {
              this.status = null;
              this.stakeKey.state = true;
            } else {
              this.stakeKey.value = "";
              this.stakeKey.state = false;
              this.stakeKey.message = message;
            }
          });
        }
      }
      this.stakeKey.loading = false;
    },
    async getAddrFromAdahandle(adahandle) {    
      if (adahandle.charAt(0) === "$") {
        adahandle = adahandle.substr(1);
      }

      const assetName = Buffer.from(adahandle).toString("hex");
      const config = {
        method: "get",
        url: `https://api.koios.rest/api/v0/asset_address_list?_asset_policy=f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a&_asset_name=${assetName}`,
        headers: { "Content-Type": "application/json" },
      };
      try {
        const { data } = await axios(config);

        this.stakeKey.value = data[0].payment_address;
        this.sendRequest = true;

      } catch (error) {
        console.log(error);
      }
    },
    runWhitelistDateLogic() {
      if (this.whitelistDate && this.stakeKey.state === null) {
        const whitelistDateMoment = moment(this.whitelistDate.toDate());
        if (whitelistDateMoment.format() >= moment().format()) {
          this.status = "whitelist";
          return true;
        }
      }
      return false;
    },
    handleStatus() {
      switch (this.status) {
        case this.collect && null:
          this.checkoutMessage = `<p>To start the collection of your <b>${this.collectionData.title}</b> please click the collect button below.</p>
            <p>Once you click the buy button you will be entered into the queue.</p>
            <div class="alert alert-dark" role="alert" style="font-size:14px;">
                Before making any investment decisions, conduct your own due diligence and consult a financial advisor.
                The information provided on our site(s) does not constitute legal, tax, investment, financial, or other advice.
                Nothing contained on our site(s) constitutes a solicitation, recommendation, endorsement of any services or products.
            </div>`;
          break;
        case null:
          this.checkoutMessage = `<p>To start your purchase of <b>${this.collectionData.title}</b> please click the buy button below.</p>
            <p>Once you click the buy button you will be entered into the purchasing queue.</p>
            <div class="alert alert-dark" role="alert" style="font-size:14px;">
                Before making any investment decisions, conduct your own due diligence and consult a financial advisor.
                The information provided on our site(s) does not constitute legal, tax, investment, financial, or other advice.
                Nothing contained on our site(s) constitutes a solicitation, recommendation, endorsement of any services or products.
            </div>`;
          break;

        case this.collect && "whitelist":
          this.checkoutMessage = `<p>This is a token collection service and your wallet must be whitelisted to continue.</p>
            <p>All payments are returned along with your free token,minus transaction fees.</p>
          `;
          break;

        case "whitelist":
          this.checkoutMessage = `The sale is only currently open for whitelisted wallets`;
          break;

        case "not started":
          this.checkoutMessage = `The sale has not yet started, please check back later`;
          break;

        case "queued":
          this.checkoutMessage = `<p>Queuing please wait...</p>
              <p>All queue positions are maintained, once it is your turn the purchase details will be shown. 
              You MUST send the exact payment amount shown including the dust (0.00xxxx), failure to do so will result in a refund.</p>
              <p>Only send a single payment to the address shown, opening other browser windows (even on other devices) 
              will show you the same address. Sending multiple payments to the same address will result in a refund.</p> 
              <p>Please wait, your payment details will be shown here as soon as a slot becomes available.</p>`;
          break;

        case this.collect && "pending":
          this.checkoutMessage = `<p>Please make your transaction. Make sure to send the correct amount including the dust</p>
          <p><i>The amount you send will be returned to you with your token, minus the transaction fee of around ₳0.2</i></p>`;
          break;

        case "pending":
          this.checkoutMessage = `Please make your payment. Make sure to send the correct amount including the dust`;
          break;

        case this.collect && "available":
          this.checkoutMessage = `<p>Please make your transaction. Make sure to send the correct amount including the dust</p>
          <p><i>The amount you send will be returned to you with your token, minus the transaction fee of around ₳0.2</i></p>`;
          break;

        case "available":
          this.checkoutMessage = `Please make your payment. Make sure to send the correct amount including the dust`;
          break;

        case "paid":
          this.price = null;
          this.address = null;
          this.countdown = null;
          this.checkoutMessage = `<p>You rock... Your payment has been verified.</p>
              <p>We have queued your token to be minted and it will be delivered soon.</p>
              <p>Be aware that at times this could take an hour or more, please be patient.</p>`;
          this.stakeKey.state = null;
          this.stakeKey.message = null;
          break;

        case "failed":
          this.price = null;
          this.address = null;
          this.countdown = null;
          this.checkoutMessage = `<p>Your transaction failed, it is likely an incorrect payment amount was detected. 
              If you sent any ADA it will be refunded.</p> 
              <p>Please try again</p>`;
          this.stakeKey.state = null;
          this.stakeKey.message = null;
          this.buyerDocUnsubscribe();
          break;

        case "expired":
          this.price = null;
          this.address = null;
          this.countdown = null;
          this.checkoutMessage = `<p>Your transaction failed to process in time, if you sent any ADA it will be refunded.</p>
              <p>Please try again</p>`;
          this.stakeKey.state = null;
          this.stakeKey.message = null;
          this.buyerDocUnsubscribe();
          break;

        case "ended":
          this.price = null;
          this.address = null;
          this.countdown = null;
          this.checkoutMessage = `Sorry this sale has ended`;
          this.stakeKey.state = null;
          this.stakeKey.message = null;
          break;

        case "sold out":
          this.price = null;
          this.address = null;
          this.countdown = null;
          this.checkoutMessage = `Sorry this collection has sold out`;
          this.stakeKey.state = null;
          this.stakeKey.message = null;
          break;

        case "exhausted":
          this.price = null;
          this.address = null;
          this.countdown = null;
          this.checkoutMessage = `<p>Sold Out</p>
              <p>Based on the volume of people currently queued for purchase and number of items 
              still available for sale this release has now sold out.</p>`;
          this.stakeKey.state = null;
          this.stakeKey.message = null;
          break;

        case "timeout":
          this.price = null;
          this.address = null;
          this.countdown = null;
          this.checkoutMessage = `<p>Please hold tight whilst we check for your payment one more time.</p>
              <p>Once this address has been checked, you can join the queue again (if there is still stock available).</p>`;
          this.stakeKey.state = null;
          this.stakeKey.message = null;
          break;

        default:
          this.price = null;
          this.address = null;
          this.countdown = null;
          this.checkoutMessage = "";
          this.stakeKey.state = null;
          this.stakeKey.message = null;
          break;
      }
    },
  },
  filters: {
    lovelace(price) {
      return price / 1000000;
    },
  },
  watch: {
    exhaustedLimit() {
      this.checkExhaustedLimit();
    },
    position() {
      this.checkExhaustedLimit();
    },
    collectionData(after, before) {
      if (before === null && after !== null) {
        this.handleStatus();
      }
    },
    status() {
      this.handleStatus();
    },
  },
};
</script>

<style scoped>
.small-text {
  font-size: 12px;
}
.copy-button {
  cursor: pointer;
}
</style>
